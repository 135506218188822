// extracted by mini-css-extract-plugin
export var body = "layout-module--body--7f99f";
export var bodyWrapper = "layout-module--body-wrapper--3fe0a";
export var companyInfo = "layout-module--company-info--b6fa9";
export var footer = "layout-module--footer--95c70";
export var footerItem = "layout-module--footer-item--42b2f";
export var header = "layout-module--header--6a868";
export var icon = "layout-module--icon--5bfd2";
export var layout = "layout-module--layout--260ed";
export var text = "layout-module--text--c304e";
export var wide = "layout-module--wide--46978";