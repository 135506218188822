import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BurgerMenu from './burgerMenu';

import classNames from 'classnames/bind';
import * as styles from './layout.module.scss';

const cx = classNames.bind(styles);

type Props = {
  pageContext: {
    lang?: string;
    wide?: boolean;
  };
};

const Layout: React.FC<Props> = ({ children, pageContext: { lang, wide } }) => {
  const { mpapi } = useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
    query Layout {
      mpapi {
        pageInfo {
          data {
            attributes {
              title
              logo {
                data {
                  attributes {
                    url
                    alternativeText
                    caption
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (!mpapi) return null;
  const pageInfo = mpapi.pageInfo.data.attributes;

  if (!pageInfo.title) return null;

  // const navItems = [
  //   {
  //     link: '/news/',
  //     title: 'News',
  //   },
  //   {
  //     link: '/',
  //     title: 'Projects',
  //   },
  //   {
  //     link: '/about/',
  //     title: 'About',
  //   },
  //   {
  //     link: '/contact/',
  //     title: 'Contact',
  //   },
  // ];

  const navItems = [];

  return (
    <div className={cx('layout')}>
      <Helmet
        htmlAttributes={{
          lang: lang || 'en',
        }}
      >
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Helmet>
      <header className={cx('header')}>
        <Link className={cx('companyInfo')} to="/">
          <img
            src={pageInfo.logo.data.attributes.url}
            alt={pageInfo.logo.data.attributes.alternativeText}
            className={cx('icon')}
          />
          <h2 className={cx('text')}>{pageInfo.title}</h2>
        </Link>

        {/* <BurgerMenu navItems={navItems} /> */}
      </header>
      <div className={cx('bodyWrapper')}>
        <main className={cx('body', { wide })}>{children}</main>
      </div>
      <footer className={cx('footer')}>
        <span className={cx('footerItem')}>
          Copyright 2024 Atelier Mendgen Peissl
        </span>
        {/* <Link className={cx('footerItem')} to="/imprintDataProtection/">
          Imprint & Data Protection
        </Link> */}
      </footer>
    </div>
  );
};

export default Layout;
